<template>
  <button @click="openModalEventView" class="btn btn--outline">
    Event View
  </button>
  <DialogContainerTest theme="schedule-event-create">
    <ScheduleEventView :formData="formData" />
  </DialogContainerTest>
</template>

<script>
import ScheduleEventView from "@component/Schedule/ScheduleEventView";
import DialogContainerTest from "@test/page/DialogContainerTest";
export default {
  inheritAttrs: false,
  components: {
    ScheduleEventView,
    DialogContainerTest,
  },
  data() {
    return {
      formData: {
        title: "Simple title",
        to: ["Sincere@april.biz"],
        description: "Simple text",
        datePeriod: {
          start: DateTime("2022-03-30T08:30:00Z"),
          end: DateTime("2022-03-30T11:30:00Z"),
        },
        repeat: 3,
        permissions: { modifyEvent: true, inviteOthers: true },
      },
    };
  },
  methods: {
    openModalEventView() {
      //
      let modal = null;
      const closeModal = () => {
        modal && modal.close();
      };
      const props = {
        // data
        communityList: this.communityList,
        // events
        onSave: (submitObj) => {
          communityAdd(submitObj.community, submitObj.contactList);
          closeModal();
        },
      };
      //
      modal = this.$dialog.open(ScheduleEventView, props, {
        group: "modal",
        theme: "event",
        close: false,
      });
      modal.then(() => {
        modal = null;
      });
    },
  },
};
</script>
