<template>
  <div class="page page-test-dialog">
    <div class="dlg">
      <div class="dlg-container dlg-container-modal-test">
        <div class="dlg-item" :class="'dlg-item__' + theme">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DialogContainerTest",
  props: {
    theme: {
      type: String,
      default: "default",
    },
  },
};
</script>

<style lang="scss" scoped>
.page-test-dialog {
  background: #cccccc;
}
.dlg-container-modal-test .dlg-item {
  padding: 40px 0;
  margin: 50px auto;
}

.dlg-container-modal-test ::v-deep(.dlg-item__file-storage),
.dlg-container-modal-test ::v-deep(.dlg-item__schedule-event-create) {
  padding: 2.5rem;
  position: relative;
}
</style>
