<template>
  <div class="schedule-event-view sev">
    <div class="sev__heading">
      <div :class="['sev__dot', `sev__dot--${type}`]"></div>
      <p class="sev__title">{{ title }}</p>

      <p class="sev__description" style="margin: 2px 0">
        <span
          style="
            background-color: rgba(86, 107, 214, 0.16);
            color: var(--color-secondary);
            font-size: 0.875rem;
            padding: 0.25rem 0.5rem;
            border-radius: 0.25rem;
          "
          >{{ community?.name || 'Pesonal' }}</span
        >
      </p>

      <template v-if="datePeriod && type === $enum.SCHEDULE_EVENT_TYPE.MEETING">
        <p class="sev__description">
          {{ datePeriod.start.format('dddd, MMMM D') }}&nbsp;&nbsp;&nbsp;{{
            datePeriod.start.format('HH:MM A')
          }}-{{ datePeriod.end.format('HH:MM A') }}
        </p>
      </template>
      <template v-else-if="dateItem">
        <p class="sev__description">
          {{ dateItem.format('dddd, MMMM D') }}&nbsp;&nbsp;&nbsp;{{
            dateItem.format('HH:MM A')
          }}
        </p>
      </template>
    </div>
    <div class="sev__setting__list">
      <div
        class="sev__setting__item"
        v-if="participantsCount && type !== $enum.SCHEDULE_EVENT_TYPE.TASK"
      >
        <!-- prettier-ignore -->
        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'sev__setting__icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M8 11a3 3 0 100-6 3 3 0 000 6zM9.385 16.71c-.155.448-.17.887-.078 1.29H4c-1.105 0-2.031-.92-1.67-1.963a6.002 6.002 0 019.498-2.658 7.006 7.006 0 00-2.443 3.33zM19 9a3 3 0 11-6 0 3 3 0 016 0zM20 19c1.105 0 2.031-.92 1.67-1.963a6.002 6.002 0 00-7.966-3.58 6 6 0 00-3.374 3.58C9.97 18.08 10.896 19 12 19h8z"/></svg>
        <div class="sev__setting__inner">
          <div class="sev__contact__info">
            <div class="sev__contact__count">
              {{ participantsCount }} guests
            </div>
            <div class="sev__contact__permissions">Permissions</div>
            <!-- TODO: Set computed props for participants invite statuses -->
            <div class="sev__contact__responses">
              <template v-if="acceptCount"> {{ acceptCount }} yes, </template>
              <template v-if="declineCount"> {{ declineCount }} no, </template>
              <template v-if="maybeCount"> {{ maybeCount }} maybe, </template>
              {{ noAnswerCount }} awaiting
            </div>
          </div>
          <div class="sev__contact__list">
            <template v-for="participant in to" :key="participant.id">
              <div class="sev__contact__item">
                <Avatar
                  :name="`${participant.firstName} ${participant.lastName}`"
                  :image="participant.profilePhoto"
                  class="sev__contact__avatar"
                />
                <p class="sev__contact__name">
                  <template
                    v-if="participant.firstName || participant.lastName"
                  >
                    {{ participant.firstName }} {{ participant.lastName }}
                  </template>
                  <template v-else>{{ participant.mail }}</template>
                </p>
                <p class="sev__contact__email">{{ participant.mail }}</p>
              </div>
            </template>
          </div>
        </div>
      </div>
      <div class="sev__setting__item" v-if="assignedCount">
        <!-- prettier-ignore -->
        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'sev__setting__icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M8 11a3 3 0 100-6 3 3 0 000 6zM9.385 16.71c-.155.448-.17.887-.078 1.29H4c-1.105 0-2.031-.92-1.67-1.963a6.002 6.002 0 019.498-2.658 7.006 7.006 0 00-2.443 3.33zM19 9a3 3 0 11-6 0 3 3 0 016 0zM20 19c1.105 0 2.031-.92 1.67-1.963a6.002 6.002 0 00-7.966-3.58 6 6 0 00-3.374 3.58C9.97 18.08 10.896 19 12 19h8z"/></svg>
        <div class="sev__setting__inner">
          <div class="sev__contact__info">
            <div class="sev__contact__count">{{ assignedCount }} assigned</div>
            <div class="sev__contact__permissions">Permissions</div>
            <!-- TODO: Set computed props for participants invite statuses -->
          </div>
          <div class="sev__contact__list">
            <template v-for="assignedUser in assignee" :key="assignedUser.id">
              <div class="sev__contact__item">
                <Avatar
                  :name="`${assignedUser.firstName} ${assignedUser.lastName}`"
                  :image="assignedUser.profilePhoto"
                  class="sev__contact__avatar"
                />
                <p class="sev__contact__name">
                  {{ assignedUser.firstName }} {{ assignedUser.lastName }}
                </p>
                <p class="sev__contact__email">{{ assignedUser.mail }}</p>
              </div>
            </template>
          </div>
        </div>
      </div>
      <div class="sev__setting__item">
        <!-- prettier-ignore -->
        <!--        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'sev__setting__icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M18.815 13.24a.864.864 0 010 1.188l-5.148 5.349a5.514 5.514 0 01-8.008 0c-2.212-2.298-2.212-6.023 0-8.32l7.436-7.726a3.939 3.939 0 015.72 0c1.58 1.64 1.58 4.302 0 5.943l-6.864 7.131a2.363 2.363 0 01-3.432 0 2.591 2.591 0 010-3.565l4.576-4.755a.788.788 0 011.144 0 .864.864 0 010 1.189l-4.576 4.754a.864.864 0 000 1.189.788.788 0 001.144 0l6.864-7.132a2.591 2.591 0 000-3.566 2.363 2.363 0 00-3.432 0l-7.436 7.726c-1.58 1.641-1.58 4.302 0 5.943a3.938 3.938 0 005.72 0l5.148-5.348a.788.788 0 011.144 0z"/></svg>-->
        <div class="sec__setting__inner">
          <!--          <p>We are glad to {{ title }}</p>-->
          <div class="sev__attachment__list">
            <template v-for="(file, i) in fileList" :key="i">
              <Tag
                :label="file.filename"
                class="sev__attachment__item"
                @click="fileStorageGetPreviewFix(file)"
              />
            </template>
          </div>
          <template v-if="files?.length > fileCountView && !fileAllShow">
            <p class="sev__attachment__more" @click="fileAllShow = true">
              {{ files.length - fileCountView }} more
            </p>
          </template>
        </div>
      </div>
      <!--      <div class="sev__setting__item">-->
      <!--        &lt;!&ndash; prettier-ignore &ndash;&gt;-->
      <!--        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'sev__setting__icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M17.523 9.438c0 1.004.274 1.596.875 2.278.456.503.602 1.15.602 1.849 0 .7-.237 1.363-.711 1.902a3.775 3.775 0 01-2.388 1.13c-1.295.107-2.589.198-3.9.198-1.313 0-2.607-.054-3.901-.198a3.775 3.775 0 01-2.389-1.13A2.838 2.838 0 015 13.565c0-.7.146-1.346.602-1.849.62-.682.875-1.274.875-2.278v-.342c0-1.345.346-2.225 1.057-3.086C8.591 4.753 10.287 4 11.964 4h.072c1.713 0 3.463.79 4.503 2.1.674.843.984 1.687.984 2.996v.342zm-7.2 8.345c.41-.086 2.92-.086 3.331 0 .352.079.733.262.733.665-.02.384-.252.724-.573.939a3.04 3.04 0 01-1.41.586 3.251 3.251 0 01-.83 0 3.04 3.04 0 01-1.412-.585v-.001c-.32-.215-.552-.555-.572-.939 0-.403.38-.586.732-.665z"/><rect x="14" y="3" width="6" height="6" rx="3" class="new-notify"/></svg>-->
      <!--        <div class="sec__setting__inner">-->
      <!--          <p>10 minutes before</p>-->
      <!--        </div>-->
      <!--      </div>-->

      <div
        class="sev__setting__item"
        v-if="type === $enum.SCHEDULE_EVENT_TYPE.MEETING"
      >
        <div class="sec__setting__inner">
          <button
            class="btn btn-sm btn--secondary"
            type="button"
            @click.prevent="openMeeting"
          >
            Connect to meeting
          </button>
        </div>
      </div>

      <div class="sev__setting__item">
        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'sev__setting__icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M4 12c0-2.809 0-4.213.674-5.222a4 4 0 011.104-1.104C6.787 5 8.19 5 11 5h2c2.809 0 4.213 0 5.222.674.437.292.812.667 1.104 1.104C20 7.787 20 9.19 20 12c0 2.809 0 4.213-.674 5.222a4.003 4.003 0 01-1.104 1.104c-.563.377-1.25.543-2.237.616L14.5 21l-1.443-2H11c-2.809 0-4.213 0-5.222-.674a4.002 4.002 0 01-1.104-1.104C4 16.213 4 14.81 4 12zm13 0a1 1 0 10-2 0 1 1 0 002 0zm-4 0a1 1 0 10-2 0 1 1 0 002 0zm-5-1a1 1 0 110 2 1 1 0 010-2z"/></svg>
        <div class="sec__setting__inner">
          <div>Description:</div>
          <p style="margin-top: 4px">{{ description }}</p>
        </div>
      </div>
    </div>

    <template v-if="type === $enum.SCHEDULE_EVENT_TYPE.MEETING">
      <div class="sev__btn-group">
        <p class="sev__question">Are you going?</p>
        <button
          type="button"
          class="btn btn--outline"
          @click="setInviteStatus($enum.SCHEDULE_EVENT_STATUS.DECLINED)"
        >
          No
        </button>
        <button
          type="button"
          class="btn btn--outline"
          @click="setInviteStatus($enum.SCHEDULE_EVENT_STATUS.MAYBE)"
        >
          Maybe
        </button>
        <button
          type="button"
          class="btn btn--primary"
          @click="setInviteStatus($enum.SCHEDULE_EVENT_STATUS.ACCEPTED)"
        >
          Yes
        </button>
      </div>
    </template>
  </div>
</template>

<script>
import Avatar from '@component/Avatar';
import {fileStorageGetPreview, fileStorageGetPreviewByUrl} from '@store/fileStorage.store';
import Tag from '@component/Tag';

export default {
  components: {
    Avatar,
    Tag,
  },
  data() {
    return {
      fileCountView: 1,
      fileAllShow: false,
    };
  },
  // prettier-ignore
  props: {
    dateItem:    { type: DateTime, default: () => null }, // DateTime,
    datePeriod:  { type: Object,   default: () => ({}), field: { required: true } }, // { start: DateTime, end: DateTime, },
    date:        { type: Object, default: () => null },
    id:          { type: String, default: () => "" },
    to:          { type: Array,  default: () => [] },
    assignee:    { type: Array,  default: () => [] },
    title:       { type: String, default: () => "" },
    permissions: { type: Object, default: () => ({}) },
    community:   { type: Object, default: () => ({}) },
    description: { type: String, default: () => "" },
    repeat: { type: Number, default: () => 1 },
    type: {
      type: String,
      default: () => this.$enum.SCHEDULE_EVENT_TYPE.MEETING,
    },
    files: { type: Array, default: () => [] }
  },
  computed: {
    fileList() {
      return (
        (this.fileAllShow
          ? this.files
          : this.files?.slice(0, this.fileCountView)) || []
      );
    },
    participantsCount() {
      return this.to.length;
    },

    assignedCount() {
      return this.assignee.length;
    },

    noAnswerCount() {
      return this.statusCount(this.$enum.SCHEDULE_EVENT_STATUS.INVITED);
    },

    maybeCount() {
      return this.statusCount(this.$enum.SCHEDULE_EVENT_STATUS.MAYBE);
    },

    acceptCount() {
      return this.statusCount(this.$enum.SCHEDULE_EVENT_STATUS.ACCEPTED);
    },

    declineCount() {
      return this.statusCount(this.$enum.SCHEDULE_EVENT_STATUS.DECLINED);
    },
  },
  methods: {
    fileStorageGetPreviewFix(file) {
      // c fileStorageGetPreview возникает ошибка, так бакет берется по умолчанию от текущего пользователя.
      return fileStorageGetPreviewByUrl(file);
    },
    // fileStorageGetPreview,
    statusCount(status) {
      let count = 0;
      this.to.forEach((item) => {
        if (item.status === status) {
          count++;
        }
      });
      return count;
    },
    setInviteStatus(status) {
      this.$emit('setEventStatus', status);
    },
    openMeeting() {
      this.$emit('openMeeting', this.id);
    },
  },
};
</script>

<style lang="scss" scoped>
.sev {
  font-weight: 400;
}

.sev__icon__group {
  display: flex;
  margin: 0 -0.375rem;
  //position: absolute;
  //top: 0.5rem;
  //right: 0.5rem;
  .sev__icon {
    @include icon-size(1);
    margin: 0 0.375rem;
    fill: var(--color-gray-04);
    cursor: pointer;
    &--trash {
      fill: var(--color-red-03);
    }
  }
}
.sev__contact__info {
  display: grid;
  grid-template-columns: repeat(2, max-content);
  grid-gap: 0 1rem;
  .sev__contact__responses {
    grid-column: 1 / 3;
    color: var(--color-gray-05);
    font-size: 0.75rem;
  }
}

.sev__attachment__list {
  grid-gap: 0.5rem;
  margin: 0.5rem 0;
}
.sev__attachment__more {
  cursor: pointer;
  font-weight: 700;
}

.sev__attachment__item {
  cursor: pointer;
}

.sev__contact__list {
  grid-gap: 1rem;
  margin-top: 1rem;
}
.sev__contact__list,
.sev__attachment__list {
  display: grid;
  grid-auto-flow: row;
}
.sev__contact__item {
  display: grid;
  grid-template-columns: min-content 1fr;
  grid-template-rows: repeat(2, 1fr);
  align-items: center;
  grid-gap: 0 0.5rem;
  line-height: 1;
  .sev__contact__avatar {
    @include icon-size(2);
    grid-row: 1 / 3;
  }
  .sev__contact__name {
    font-size: 1rem;
    line-height: 1.1;
    color: var(--color-gray-07);
  }
  .sev__contact__email {
    color: var(--color-gray-05);
  }
  .sev__contact__name,
  .sev__contact__email {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.sev__setting__item {
  width: 100%;
}
.sev__btn-group {
  display: flex;
  margin: 0 -0.5rem;
  align-items: center;
  .btn {
    margin: 0 0.5rem;
  }
}
.sev__question {
  margin: 0 auto 0 2.75rem;
  font-size: 0.875rem;
  color: var(--color-gray-06);
}

.sev__setting__icon {
  fill: var(--color-secondary-light);
  @include icon-size(1.25);
  margin-right: 1rem;
}

.sev__setting__inner {
  max-width: 350px;
}

.sev__setting__item {
  display: flex;
  align-items: flex-start;
  font-size: 0.875rem;
  padding: 0.75rem 0;
  color: var(--color-gray-06);
  &--contact {
    align-items: center;
  }
}

.sev__heading {
  display: grid;
  grid-template-columns: min-content 1fr;
  grid-template-rows: repeat(2, 1fr);
  align-items: center;
  grid-gap: 0 1rem;
  margin-bottom: 1rem;
}

.sev__title {
  color: var(--color-gray-08);
  font-weight: 600;
}

.sev__description {
  font-size: 0.875rem;
  color: var(--color-gray-06);
  grid-column: 2 / 3;
}

.sev__dot {
  // position: absolute;
  // top: 50%;
  // transform: translateY(-50%);
  @include icon-size(1.25);
  display: flex;
  justify-content: center;
  grid-column: 1;
  grid-row: 1 / 2;
  align-items: center;
  &::before {
    content: '';
    display: block;
    @include icon-size(0.625);
    border-radius: 100%;
  }
  &--meeting::before {
    background-color: var(--color-secondary);
  }
  &--task::before {
    background-color: var(--color-cyan);
  }
  &--reminder::before {
    background-color: var(--color-yellow);
  }
}
</style>
